import React from "react";

import Layout from "../../components/Layout/layout";
import SEO from "../../components/SEO/seo";
import ContactUs from "../../components/contact-us";
import TaxiToEindhovenComponent from "../../components/TaxiToEindhoven/index";

const TaxiToEindhoven = () => (
	<Layout>
		<SEO
			title="Online Taxi Neumünster nach Kiel, Hamburg | Taxi Neumünster"
			meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
			description="Holen Sie sich schnell, sicher, Online-Taxi Neumünster nach Kiel und genießen Sie eine eingängige Erfahrung, die jemals mit seltenen fälligen verbunden wurde."
		/>
		<TaxiToEindhovenComponent />
	</Layout>
);

export default TaxiToEindhoven;
